export function LoginData(type, loginDetails) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(loginDetails)
        };
        fetch("https://test.mokhasangham.com/api/" + type, requestOptions)
            .then((response) => response.json())
            .then((responseJSON) => {
                resolve(responseJSON)
            })
            .catch((error) => {
                reject(error)
            })
    })
}