import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";

class AdminContactUs extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="row g-4">
                            <div className="col-sm-12">
                                <div className="shadow rounded p-4 table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Full Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Website</th>
                                            <th scope="col">Description</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>John Doe</td>
                                            <td>jhon@email.com</td>
                                            <td>7878787878</td>
                                            <td>aimcrafters.com</td>
                                            <td>Hello, Test.</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Mark Otto</td>
                                            <td>mark@email.com</td>
                                            <td>7878787878</td>
                                            <td>aimcrafters.com</td>
                                            <td>Hello, Test.</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Jacob Thornton</td>
                                            <td>jacob@email.com</td>
                                            <td>7878787878</td>
                                            <td>aimcrafters.com</td>
                                            <td>Hello, Test.</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminContactUs;