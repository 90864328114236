import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Button, Form, Row, Col, Alert} from "react-bootstrap";
import {GoogleReCaptchaProvider, GoogleReCaptcha} from "react-google-recaptcha-v3";
import {PostData} from "../api_key/PostData";

class RegisterYourInterest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            first_nameErr: "",
            last_name: "",
            last_nameErr: "",
            father_name: "",
            father_nameErr: "",
            grand_father_name: "",
            grand_father_nameErr: "",
            email_address: "",
            email_addressErr: "",
            phone_number: "",
            phone_numberErr: "",
            vas_name: "",
            vas_nameErr: "",
            your_address: "",
            your_addressErr: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            reCaptchaToken: "",
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.submitEventRegistrationForm = this.submitEventRegistrationForm.bind(this)
        this.setTokenValue = this.setTokenValue.bind(this)
    }

    setTokenValue(token) {
        this.setState({reCaptchaToken: token})
    }

    submitEventRegistrationForm() {
        let formData = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            father_name: this.state.father_name,
            grand_father_name: this.state.grand_father_name,
            email_address: this.state.email_address,
            phone_number: this.state.phone_number,
            vas_name: this.state.vas_name,
            your_address: this.state.your_address,
            googleRecaptchaResponse: this.state.reCaptchaToken
        }

        PostData("/front/event_registration", formData)
            .then(result => {
                console.log(result)
            })
    }

    handleClose() {
        this.setState({
            first_name: "",
            first_nameErr: "",
            last_name: "",
            last_nameErr: "",
            father_name: "",
            father_nameErr: "",
            grand_father_name: "",
            grand_father_nameErr: "",
            email_address: "",
            email_addressErr: "",
            phone_number: "",
            phone_numberErr: "",
            vas_name: "",
            vas_nameErr: "",
            your_address: "",
            your_addressErr: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        return (<>
            <Header/>
            <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

            <div className="content">
                <section className="sec-100px contact-us">
                    <div className="container">
                        <div className="contact-form">
                            <Form id="contact_form" className="contact-form" method="post">
                                <Row as={"ul"}>
                                    <Col sm={12}>
                                        <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                        </Alert>
                                    </Col>
                                    <Col as={"li"} sm={6}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="first_name"
                                                          id="first_name"
                                                          placeholder="FIRST NAME"
                                                          value={this.state.first_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.first_nameErr && <span className={"text-danger"}>{this.state.first_nameErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={6}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="last_name"
                                                          id="last_name"
                                                          placeholder="LAST NAME"
                                                          value={this.state.last_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.last_nameErr && <span className={"text-danger"}>{this.state.last_nameErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={6}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="father_name"
                                                          id="father_name"
                                                          placeholder="FATHER NAME"
                                                          value={this.state.father_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.father_nameErr && <span className={"text-danger"}>{this.state.father_nameErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={6}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="grand_father_name"
                                                          id="grand_father_name"
                                                          placeholder="GRAND FATHER NAME"
                                                          value={this.state.grand_father_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.grand_father_nameErr && <span className={"text-danger"}>{this.state.grand_father_nameErr}</span>}
                                        </label>
                                    </Col>
                                </Row>
                                <Row as={"ul"}>
                                    <Col as={"li"} sm={6}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="email_address"
                                                          id="email_address"
                                                          placeholder="EMAIL"
                                                          value={this.state.email_address}
                                                          onChange={this.changeValue}/>
                                            {this.state.email_addressErr && <span className={"text-danger"}>{this.state.email_addressErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={6}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="phone_number"
                                                          id="phone_number"
                                                          placeholder="PHONE"
                                                          value={this.state.phone_number}
                                                          onChange={this.changeValue}/>
                                            {this.state.phone_numberErr && <span className={"text-danger"}>{this.state.phone_numberErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={12}>
                                        <label>
                                            <Form.Control as={"textarea"}
                                                          name="vas_name"
                                                          id="vas_name"
                                                          rows={3}
                                                          placeholder="VAS NAME"
                                                          value={this.state.vas_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.vas_nameErr && <span className={"text-danger"}>{this.state.vas_nameErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={12}>
                                        <label>
                                            <Form.Control as={"textarea"}
                                                          name="your_address"
                                                          id="your_address"
                                                          rows={3}
                                                          placeholder="ADDRESS"
                                                          value={this.state.your_address}
                                                          onChange={this.changeValue}/>
                                            {this.state.your_addressErr && <span className={"text-danger"}>{this.state.your_addressErr}</span>}
                                        </label>
                                    </Col>
                                </Row>

                                <div className="text-center">
                                    <Button variant={"primary"} onClick={this.submitEventRegistrationForm}>Submit</Button>
                                    <Button variant={"primary"} onClick={this.handleClose} className={"ml-2"}>Reset</Button>
                                </div>

                                <GoogleReCaptchaProvider
                                    reCaptchaKey="6Lcni2wkAAAAAKykRhLubTzdf1b_ew-uKwQO3Wnc"
                                    scriptProps={{
                                        async: false, // optional, default to false,
                                        defer: false, // optional, default to false
                                        appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                        nonce: undefined // optional, default undefined
                                    }}
                                >
                                    <GoogleReCaptcha onVerify={this.setTokenValue}/>
                                </GoogleReCaptchaProvider>
                            </Form>
                        </div>
                    </div>
                </section>
            </div>

            <Footer/>
        </>)
    }
}

export default RegisterYourInterest;