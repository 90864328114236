import React from "react";
import $ from "jquery";
import {Button} from "react-bootstrap";

class AdminFooter extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (<>
                <div className="container-fluid pt-4">
                    <div className="p-4 border-top border-light">
                        <div className="row">
                            <div className="col-12 text-center text-sm-start">
                                &copy; Mokhasan, All Right Reserved.
                            </div>
                        </div>
                    </div>
                </div>

                <Button onClick={()=>{
                    $('html, body').animate({scrollTop: 0}, 1500);
                }} variant={"primary"} size={"lg"} className="btn-lg-square back-to-top">
                    <em className="fa fa-chevron-circle-up"></em>
                </Button>
            </>
        );
    }
}

export default AdminFooter;