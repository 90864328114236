import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";

class AdminHome extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="row g-4">
                            <div className="col-sm-6 col-xl-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-line-chart fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Today Sale</p>
                                        <h6 className="text-dark mb-0">$1234</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-bar-chart fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Total Sale</p>
                                        <h6 className="text-dark mb-0">$1234</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-area-chart fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Today Revenue</p>
                                        <h6 className="text-dark mb-0">$1234</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-pie-chart fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Total Revenue</p>
                                        <h6 className="text-dark mb-0">$1234</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminHome;