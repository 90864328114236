import React from "react";
import {Row, Col, Button} from "react-bootstrap";
import $ from "jquery";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    showLargeImage(e) {
        $("#large_image_content_img").attr("src", e.currentTarget.getAttribute("data-src"))
        $("#large_image_content").removeClass("d-none")
    }

    render() {
        return (<>
                <Header/>
                <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

                <div className="content">
                    <section className="sec-100px gallery bg-white">
                        <div className="container">
                            <Row as={"ul"}>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3957.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3957.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3964.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3964.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3970.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3970.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3978.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3978.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3988.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3988.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3989.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3989.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4006.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4006.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4010.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4010.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4028.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4028.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4041.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4041.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4047.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4047.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4048.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4048.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4055.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4055.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4070.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4070.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4091.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4091.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4112.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4112.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4124.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4124.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4128.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4128.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4131.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4131.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4134.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4134.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4139.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4139.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4143.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4143.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4148.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4148.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4152.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4152.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4153.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4153.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4155.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4155.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4157.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4157.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4166.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4166.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4167.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4167.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4168.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4168.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4169.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4169.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4177.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4177.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4197.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4197.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4200.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4200.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4204.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4204.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4212.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4212.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4214.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4214.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4219.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4219.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4220.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4220.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4232.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4232.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4236.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4236.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4244.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4244.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4248.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4248.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4251.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4251.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4255.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4255.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4262.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4262.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4267.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4267.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4269.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4269.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_4278.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_4278.jpg" alt=""/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div id="large_image_content" className="large-image-wrapper d-none">
                            <span className="large-image-close" onClick={()=>{$(".large-image-wrapper").addClass("d-none")}}><em className="fa fa-close"></em></span>
                            <img src="" alt="" id={"large_image_content_img"}/>
                        </div>
                    </section>
                </div>

                <Footer/>
            </>
        )
    }
}

export default Gallery;