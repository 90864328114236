import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Button, Form, Row, Col} from "react-bootstrap";
import $ from "jquery"

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addFamilyMemberModal: false,
            familyMemberCount: 0,
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({
            addFamilyMemberModal: false,
        })
    }

    render() {
        return (<>
            <Header/>
            <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

            <div className="content">
                <section className="sec-100px contact-us">
                    <div className="container">
                        <div className="contact-form">
                            <Form id="contact_form" className="contact-form" method="post">
                                <Row as={"ul"}>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="name" id="name" placeholder="NAME"/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="father_name" id="father_name" placeholder="FATHER NAME"/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="grand_father_name" id="grand_father_name" placeholder="GRAND FATHER NAME"/>
                                        </label>
                                    </Col>
                                </Row>
                                <Row as={"ul"}>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="phone" id="phone" placeholder="PHONE"/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="email" id="email" placeholder="EMAIL"/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="vas_name" id="vas_name" placeholder="VAS NAME"/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={12}>
                                        <label>
                                            <Form.Control as={"textarea"} name="full_address" id="full_address" rows={3} placeholder="FULL ADDRESS"/>
                                        </label>
                                    </Col>
                                </Row>

                                <div id="family_member">
                                    <Button variant={"primary"} className="mb-3" onClick={() => {
                                        this.setState({familyMemberCount: this.state.familyMemberCount + 1})
                                        $("#family_member").append('<div id="family_member_' + this.state.familyMemberCount + '" class="mb-3">' +
                                            '<p>Add ' + (this.state.familyMemberCount + 1) + ' Family Member Details</p>' +
                                            '<div class="d-flex flex-wrap flex-sm-nowrap">\n' +
                                            '    <input type="text" class="form-control mb-2 mx-1 text-center" value="' + (this.state.familyMemberCount + 1) + '" style="max-width: 48px;" disabled/>\n' +
                                            '    <input type="text" class="form-control mb-2 mx-1" name="full_name_' + this.state.familyMemberCount + '" id="full_name_' + this.state.familyMemberCount + '" placeholder="FULL NAME"/>\n' +
                                            '    <input type="text" class="form-control mb-2 mx-1" name="age_' + this.state.familyMemberCount + '" id="age_' + this.state.familyMemberCount + '" placeholder="AGE"/>\n' +
                                            '    <select class="form-control mb-2 mx-1" name="gender_' + this.state.familyMemberCount + '" id="gender_' + this.state.familyMemberCount + '">\n' +
                                            '        <option value="GENDER">GENDER</option>\n' +
                                            '        <option value="Male">Male</option>\n' +
                                            '        <option value="Female">Female</option>\n' +
                                            '    </select>\n' +
                                            '    <select class="form-control mb-2 mx-1" name="married_' + this.state.familyMemberCount + '" id="married_' + this.state.familyMemberCount + '">\n' +
                                            '        <option value="MARRIED">MARRIED</option>\n' +
                                            '        <option value="Married">Married</option>\n' +
                                            '        <option value="Unmarried">Unmarried</option>\n' +
                                            '        <option value="Divorced">Divorced</option>\n' +
                                            '        <option value="Widowed">Widowed</option>\n' +
                                            '    </select>\n' +
                                            '    <select class="form-control mb-2 mx-1" name="relationship_' + this.state.familyMemberCount + '" id="relationship_' + this.state.familyMemberCount + '">\n' +
                                            '        <option value="RELATIONSHIP">RELATIONSHIP</option>\n' +
                                            '        <option value="Mother">Mother</option>\n' +
                                            '        <option value="Father">Father</option>\n' +
                                            '        <option value="Grand Father">Grand Father</option>\n' +
                                            '        <option value="Grand Mother">Grand Mother</option>\n' +
                                            '        <option value="Spouse">Spouse</option>\n' +
                                            '        <option value="Brother">Brother</option>\n' +
                                            '        <option value="Sister">Sister</option>\n' +
                                            '        <option value="Son">Son</option>\n' +
                                            '        <option value="Daughter">Daughter</option>\n' +
                                            '        <option value="Other">Other</option>\n' +
                                            '    </select>\n' +
                                            '    <button class="btn mb-2 mx-1 mt-0" type="button" onclick="$(this).parent().parent().remove()"><em class="fa fa-trash"></em></button>\n' +
                                            '</div></div>');
                                    }}>Add Family Member</Button>
                                </div>

                                <div className="text-center">
                                    <Button variant={"primary"}>Submit</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </section>
            </div>

            <Footer/>
        </>)
    }
}

export default NotFound;