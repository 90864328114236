import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    render() {
        return (<>
            <Header/>
            <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

            <div className="content">
                <div className="container">
                    <div className="error-page text-center">
                        <img src="/assets/images/404-page.jpg" alt=""/>
                        <h1>404</h1>
                        <h2>Sorry, this page isn’t available</h2>
                        <h3>The link you followed is probably broken or the page has been removed.</h3>
                    </div>
                </div>
            </div>

            <Footer/>
        </>)
    }
}

export default NotFound;