import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, ListGroup, Button} from "react-bootstrap";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import MokhasanMap from "../Common/MokhasanMap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HoursOfVisiting from "../Common/HoursOfVisiting";
import "./SliderCss.css"
import $ from "jquery";
import {GetData} from "../api_key/GetData";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {
                infinite: true,
                slidesToShow: 5,
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 3000,
                autoplayHoverPause: true,
                singleItem: true,
                navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
                lazyLoad: true,
                nav: false,
                loop: true,
                animateOut: 'fadeOut',
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            },
            revSlider: {
                infinite: true,
                slidesToShow: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 6000,
                autoplayHoverPause: true,
                singleItem: true,
                lazyLoad: true,
                animateOut: 'fadeOut',
            },
            galleryImageList: [],
        }
        document.title = this.props.pageTitle
        this.loadAllEvents = this.loadAllEvents.bind(this)
        this.loadAllImageGallery = this.loadAllImageGallery.bind(this)
    }

    showLargeImage(e) {
        $("#large_image_content_img").attr("src", e.currentTarget.getAttribute("data-src"))
        $("#large_image_content").removeClass("d-none")
    }

    componentDidMount() {
        this.loadAllEvents()
        this.loadAllImageGallery()
    }

    loadAllImageGallery() {
        GetData("/front/get_all_image_gallery")
            .then(result => {
                let galleryImages = result.response.map((data) =>
                    (<Col as={"li"} sm={4} key={data.uuid}>
                        <div className="inn-sec">
                            <div className="hover-info">
                                <div className="position-center-center">
                                    <Button data-src={data.file} data-rel="prettyPhoto"
                                            className="prettyPhoto lightzoom zoom" ononClick={this.showLargeImage}>
                                        <em className="ion-image"></em>
                                    </Button>
                                </div>
                            </div>
                            <img className="img-responsive" src={data.file} alt=""/>
                        </div>
                    </Col>)
                )
                this.setState({galleryImageList: galleryImages})
            })
    }

    loadAllEvents() {

    }

    render() {
        return (
            <>
                <Header/>
                <div className="content">
                    <section className="home-slider">
                        <div className="container">
                            <div className="tp-banner">
                                <Slider {...this.state.revSlider}>
                                    <div className="slider_slide">
                                        <img src="/assets/images/slides/slide-1.jpg" data-bgposition="center center" alt="" style={{width: "100%"}}/>
                                    </div>
                                    <div className="slider_slide">
                                        <img src="/assets/images/slides/slide-2.jpg" data-bgposition="center center" alt="" style={{width: "100%"}}/>
                                    </div>
                                    <div className="slider_slide">
                                        <img src="/assets/images/slides/slide-3.jpg" data-bgposition="center center" alt="" style={{width: "100%"}}/>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </section>

                    <section className="sec-100px about">
                        <div className="container">
                            <Row>
                                <Col md={7}>
                                    <h4>Welcome to Mokhasan Parivar</h4>
                                    <hr/>
                                    <p>Mokhasan is a Village in Kalol Taluka in Gandhinagar District of Gujarat State, India.</p>
                                    <p>This village have all types of caste available like Patel, Thakore, Bharmin, Raval, Harijan, Vaghri and Rabari.</p>
                                    <p>In old age one Mokha Rabari established this village.</p>
                                    <p>From all houses one or two people are in US or CANADA.</p>
                                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                                        <Link className={"register_button"} to={"/register_your_family"}>Register your family <em className="fa fa-angle-right"></em></Link>
                                        <Link className={"register_button"} to={"/register_your_daughter"}>Register your Daughter <em className="fa fa-angle-right"></em></Link>
                                    </div>
                                </Col>

                                <Col md={5}>
                                    <HoursOfVisiting/>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section className="history">
                        <Row>
                            <Col md={4} className={"no-padding"}>
                                <div className="website-img"></div>
                            </Col>
                            <Col md={8} className={"no-padding"}>
                                <div className="history-detail">
                                    <Row as={"ul"} className="row">
                                        <Col as={"li"} md={3}>
                                            <h4>Our history</h4>
                                            <hr/>
                                        </Col>
                                        <Col as={"li"} md={9}>
                                            <p>
                                                Mokhasan was founded by one Mokha Rabari in early 90s. It is located 19 KM towards west from District head quarters Gandhinagar. 10 KM from . 18 KM from State capital Gandhinagar.
                                                <br/>
                                                <br/>
                                                There are three big Swaminarayan temples one Krishna temple and one Shivaji temple in Mokhasan.
                                                <br/>
                                                <br/>
                                                Mokhasan's local language is Gujarati. Mokhasan's Total population is approximately 2841 and number of houses are 574. Female Population is 49.1%.
                                                <br/>
                                                <br/>
                                                This Village has great literacy rate with 70.4% Male and the Female Literacy rate is 31.5%.
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row as={"ul"} className="on-view">
                                        <Col as={"li"} md={3}>
                                            <h4>Places Nearby</h4>
                                            <hr/>
                                        </Col>
                                        <Col as={"li"} md={9}>
                                            <Row as="ul">
                                                <Col as={"li"} className={"col-xs-6"}>
                                                    <p>Dingucha ( 3 KM )</p>
                                                    <p>Nardipur ( 3 KM )</p>
                                                    <p>Sardhav ( 4 KM )</p>
                                                </Col>
                                                <Col as={"li"} className={"col-xs-6"}>
                                                    <p>Pansar ( 4 KM )</p>
                                                    <p>Nava ( 5 KM )</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>

                    <section className="sec-100px gallery">
                        <div className="container">
                            <div className="tittle">
                                <h2>GALLERY</h2>
                                <hr/>
                                <p>Take a look at our village places</p>
                            </div>
                            <Row as={"ul"}>
                                {this.state.galleryImageList}
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3957.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" ononClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3957.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3964.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3964.jpg" alt=""/>
                                    </div>
                                </Col>
                                <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        <div className="hover-info">
                                            <div className="position-center-center">
                                                <Button data-src="/assets/images/temple_images/DSC_3970.jpg" data-rel="prettyPhoto" className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                                    <em className="ion-image"></em>
                                                </Button>
                                            </div>
                                        </div>
                                        <img className="img-responsive" src="/assets/images/temple_images/DSC_3970.jpg" alt=""/>
                                    </div>
                                </Col>
                            </Row>
                            <div id="large_image_content" className="large-image-wrapper d-none">
                                <span className="large-image-close" onClick={()=>{$(".large-image-wrapper").addClass("d-none")}}><em className="fa fa-close"></em></span>
                                <img src="" alt="" id={"large_image_content_img"}/>
                            </div>
                        </div>
                    </section>

                    <section className="sec-100px event">
                        <div className="container">
                            <div className="tittle">
                                <h2>EVENTS</h2>
                                <hr/>
                                <p>The movie star the professor and Mary Ann here on Gilligans Isle. Just sit right back and you'll hear.</p>
                            </div>
                            <Row>
                                <Col md={12}>
                                    <ListGroup as={"ul"}>
                                        <ListGroup.Item as={"li"} className={"col-sm-6 no-padding bg-transparent border-0"}>
                                            <img className="img-responsive" src="/assets/images/temple_images/DSC_4219.jpg" alt=""/>
                                            <div className="date">
                                                    23 <p>JUNE, 23</p>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item as={"li"} className={"col-sm-6 no-padding bg-transparent border-0"}>
                                            <div className="event-detail">
                                                <h4>Mokhasan Parivar Convention</h4>
                                                <span><em className="ion-ios-location-outline"></em> Umiya Mataji Mandir Macon, Georgia</span>
                                                <span><em className="ion-ios-clock-outline"></em> June 23th to 25th </span>
                                                <p>Open invitation to all Sons and Single Married Daughters of Mokhasan and thier Families</p>
                                                <p>Everyone is welcome regardless of cast and religion. Please contact this group admins for more information.</p>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <div style={{height: "520px"}}>
                        <MokhasanMap/>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default Home;