import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import MokhasanMap from "../Common/MokhasanMap";
import HoursOfVisiting from "../Common/HoursOfVisiting";
import {Form, Row, Col, Button} from "react-bootstrap";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            contact_website: "",
            contact_description: "",
        }
        document.title = this.props.pageTitle
        this.submitContactForm = this.submitContactForm.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    submitContactForm() {
        let formData = {
            contact_name: this.state.contact_name,
            contact_email: this.state.contact_email,
            contact_phone: this.state.contact_phone,
            contact_website: this.state.contact_website,
            contact_description: this.state.contact_description,
        }
        console.log(formData)
    }

    handleClose() {
        this.setState({
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            contact_website: "",
            contact_description: "",
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        return (<>
            <Header/>
            <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

            <div className="content">
                <section className="sec-100px contact-us">
                    <div className="container">
                        <Row>
                            <Col md={7}>
                                <div className="contact-form">
                                    <Form id="contact_form" className="contact-form" method="post" onSubmit="return false">
                                        <Row as={"ul"}>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="text"
                                                                  name="contact_name"
                                                                  id="contact_name"
                                                                  placeholder="Name"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_name}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="text"
                                                                  name="contact_email"
                                                                  id="contact_email"
                                                                  placeholder="Email"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_email}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="number"
                                                                  name="contact_phone"
                                                                  id="contact_phone"
                                                                  placeholder="Phone"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_phone}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="text"
                                                                  name="contact_website"
                                                                  id="contact_website"
                                                                  placeholder="Website"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_website}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={12}>
                                                <label>
                                                    <Form.Control as="textarea"
                                                                  name="contact_description"
                                                                  id="contact_description"
                                                                  rows="5"
                                                                  placeholder="Your Need & Description"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_description}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={12}>
                                                <Button onClick={this.submitContactForm}>Submit</Button>
                                                <Button onClick={this.handleClose} className={"ml-2"}>Reset</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>

                            <Col md={5} className={"about"}>
                                <HoursOfVisiting/>
                            </Col>
                        </Row>
                    </div>
                </section>

                <div style={{height: "520px"}}>
                    <MokhasanMap/>
                </div>
            </div>

            <Footer/>
        </>)
    }
}

export default NotFound;