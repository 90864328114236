export function PostData(type, formData, token) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(formData)
        };
        fetch("https://test.mokhasangham.com/api/" + type, requestOptions)
            .then((response) => response.json())
            .then((responseJSON) => {
                resolve(responseJSON)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function uploadFiles(type, formData) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch("https://test.mokhasangham.com/api/" + type, requestOptions)
            .then((response) => response.json())
            .then((responseJSON) => {
                resolve(responseJSON)
            })
            .catch((error) => {
                reject(error)
            })
    })
}