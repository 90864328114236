import React from "react";
import {Link} from "react-router-dom";
import {ListGroup} from "react-bootstrap";

class SubBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <section className="sub-bnr sub-404" data-stellar-background-ratio="0.3">
                    <div className="overlay-gr">
                        <div className="container">
                            <h2>{this.props.bannerTitle}</h2>
                            <p>{this.props.bannerContent}</p>
                        </div>
                    </div>
                </section>
                <ListGroup as={"ol"} className="breadcrumb m-0">
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}><Link to="/">Home</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0 active"}>{this.props.bannerTitle}</ListGroup.Item>
                </ListGroup>
            </>
        )
    }
}

export default SubBanner;