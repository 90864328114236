import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Row, Col, Button} from "react-bootstrap";
import $ from "jquery";
import {Link} from "react-router-dom";

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    render() {
        return (<>
                <Header/>
                <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

                <div className="content">
                    <section className="sec-100px gallery">
                        <div className="container">
                            <div className="tittle">
                                <h2>Sponsors</h2>
                                <hr/>
                            </div>
                            <Row as={"ul"}>
                               <Col as={"li"} sm={4}>
                                    <div className="inn-sec">
                                        {/*<span className="tag">modern arts</span>*/}
                                        <img className="img-responsive w-100" src="/assets/images/profile-picture.png" alt=""/>
                                        <div className="detail">
                                            <Link to={""}>Rameshbahi Prahladbhai Patel</Link>
                                            <p><span>E-Mail</span>: roypatel57@gmail.com</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div id="large_image_content" className="large-image-wrapper d-none">
                                <span className="large-image-close" onClick={()=>{$(".large-image-wrapper").addClass("d-none")}}><em className="fa fa-close"></em></span>
                                <img src="" alt="" id={"large_image_content_img"}/>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer/>
            </>
        )
    }
}

export default Gallery;