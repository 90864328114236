import React from "react";
import {Link} from "react-router-dom";
import {Form, Button, ListGroup} from "react-bootstrap";

class EventSidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="side-bar">
                <div className="search">
                    <Form>
                        <Form.Control type="text" placeholder="SEARCH FAQ" />
                        <Button type="submit"><em className="fa fa-search"></em></Button>
                    </Form>
                </div>
                <h5>Latest News</h5>
                <ListGroup as={"ul"} className="latest-post">
                    <ListGroup.Item as={"li"} className={"bg-transparent px-0 border-0"}>
                        <div className="media">
                            <div className="media-left">
                                <Link to="/"><img src="/assets/images/temple_images/DSC_4131.jpg" alt=""/></Link>
                            </div>
                            <div className="media-body">
                                <Link to="/">Top 10 Travelers’ Choice Mokhasan in the U.S.</Link>
                                <p>Jul 19, 2015</p>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent px-0 border-0"}>
                        <div className="media">
                            <div className="media-left">
                                <Link to="/"><img src="/assets/images/temple_images/DSC_4134.jpg" alt=""/></Link>
                            </div>
                            <div className="media-body">
                                <Link to="/">Australia's largest discovered dinosaur</Link>
                                <p>Aug 07, 2015</p>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent px-0 border-0"}>
                        <div className="media">
                            <div className="media-left">
                                <Link to="/"><img src="/assets/images/temple_images/DSC_4139.jpg" alt=""/></Link>
                            </div>
                            <div className="media-body">
                                <Link to="/">Ancient fish evolved gills to survive acidic oceans</Link>
                                <p>Sep 23, 2015</p>
                            </div>
                        </div>
                    </ListGroup.Item>
                </ListGroup>

                <h5>Upcoming Events</h5>
                <div className="coming-event">
                    <ListGroup as={"ul"}>
                        <ListGroup.Item as={"li"} className={"p-2"}><Link to="/"><img src="/assets/images/temple_images/DSC_3970.jpg" alt=""/></Link></ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"p-2"}><Link to="/"><img src="/assets/images/temple_images/DSC_3978.jpg" alt=""/></Link></ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"p-2"}><Link to="/"><img src="/assets/images/temple_images/DSC_3988.jpg" alt=""/></Link></ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"p-2"}><Link to="/"><img src="/assets/images/temple_images/DSC_3989.jpg" alt=""/></Link></ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"p-2"}><Link to="/"><img src="/assets/images/temple_images/DSC_4006.jpg" alt=""/></Link></ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"p-2"}><Link to="/"><img src="/assets/images/temple_images/DSC_4010.jpg" alt=""/></Link></ListGroup.Item>
                    </ListGroup>
                </div>

                <h5>Connect With Us</h5>
                <div className="conct-with">
                    <ListGroup as={"ul"}>
                        <ListGroup.Item as={"li"} className={"px-1"}>
                            <Link className="fb" to="/">
                                <em className="fa fa-facebook"></em> <span>5169</span>
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"px-1"}>
                            <Link className="tw" to="/">
                                <em className="fa fa-twitter"></em> <span>5169</span>
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"px-1"}>
                            <Link className="g-plus" to="/">
                                <em className="fa fa-google-plus"></em> <span>5169</span>
                            </Link>
                        </ListGroup.Item>
                    </ListGroup>
                </div>

                <h5>Categories</h5>
                <ListGroup as={"ul"} className="cate">
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}><Link to="/"> American Art (23)</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}><Link to="/"> Gardens (16)</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}><Link to="/"> War History (09)</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}><Link to="/"> Kings of History (05)</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}><Link to="/"> Eastern Asia (15)</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}><Link to="/"> Skelton (11)</Link></ListGroup.Item>
                </ListGroup>

                <h5>Popular Tags</h5>
                <ListGroup as={"ul"} className="tags">
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">Art Work</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">Asia</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">Eastern Asia</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">Skelton</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">War History</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">Kings</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">Mokhasan</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">African</Link></ListGroup.Item>
                    <ListGroup.Item as={"li"} className={"p-0 bg-transparent border-0"}><Link to="/">German</Link></ListGroup.Item>
                </ListGroup>
            </div>
        )
    }
}

export default EventSidebar;