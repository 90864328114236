import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React from 'react';
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Contact from "./Pages/Contact";
import Events from "./Pages/Events";
import Gallery from "./Pages/Gallery";
import About from "./Pages/About";
import SingleEvents from "./Pages/SingleEvents";
import Sponsors from "./Pages/Sponsors";
import RegisterYourFamily from "./Pages/RegisterYourFamily";
import AdminPages from "./Admin/AdminPages";
import AdminHome from "./Admin/AdminHome";
import AdminGallery from "./Admin/AdminGallery";
import AdminLogin from "./Admin/AdminLogin";
import AdminEvents from "./Admin/AdminEvents";
import AdminFamily from "./Admin/AdminFamily";
import AdminSponsors from "./Admin/AdminSponsors";
import AdminContactUs from "./Admin/AdminContactUs";
import RegisterYourDaughter from "./Pages/RegisterYourDaughter";
import RegisterYourInterest from "./Pages/RegisterYourInterest";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={
                            <Home
                                pageTitle={"Mokhasan - Home"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/contact" element={
                            <Contact
                                pageTitle={"Mokhasan - Contact Us"}
                                bannerTitle={"Contact Us"}
                                bannerContent={"We are located in downtown Melbourne, just a few miles from Fedral Bank"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/events" element={
                            <Events
                                pageTitle={"Mokhasan - Events"}
                                bannerTitle={"Events"}
                                bannerContent={"To sponsor something is to support an event, activity, person, or organization financially"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/gallery" element={
                            <Gallery
                                pageTitle={"Mokhasan - Gallery"}
                                bannerTitle={"Gallery"}
                                bannerContent={"Take a look at our village places."}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/about" element={
                            <About
                                pageTitle={"Mokhasan - About"}
                                bannerTitle={"About"}
                                bannerContent={"We finally got a piece of the pie. Fleeing from the Cylon tyranny the last Battlestar."}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/single_events" element={
                            <SingleEvents
                                pageTitle={"Mokhasan - SingleEvents"}
                                bannerTitle={"let’s Celebrate 25th Mokhasan Day"}
                                bannerContent={"I have always wanted to have a neighbor just like you. I've always wanted to live in a neighborhood with you."}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/sponsors" element={
                            <Sponsors
                                pageTitle={"Mokhasan - Sponsors"}
                                bannerTitle={"Sponsors"}
                                bannerContent={"All the sponsors of Mokhasan parivar"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/register_your_family" element={
                            <RegisterYourFamily
                                pageTitle={"Mokhasan - Register Your Family"}
                                bannerTitle={"Register Your Family"}
                                bannerContent={"Fill below form to Register Your Family Members"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/register_your_daughter" element={
                            <RegisterYourDaughter
                                pageTitle={"Mokhasan - Register Your Daughter"}
                                bannerTitle={"Register Your Daughter"}
                                bannerContent={"Fill below form to Register Your Daughter"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/register_your_interest" element={
                            <RegisterYourInterest
                                pageTitle={"Mokhasan - Register Your Interest"}
                                bannerTitle={"Register Your Interest"}
                                bannerContent={"Fill below form to Register Your Interest"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                        <Route path="/adminPages" element={<AdminPages pageTitle={"Mokhasan - Admin Home"}/>}>
                            <Route path="" element={
                                <AdminLogin pageTitle={"Mokhasan - Admin Login"}/>
                            }/>
                            <Route path="home" element={
                                <AdminHome pageTitle={"Mokhasan - Admin Home"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="gallery" element={
                                <AdminGallery pageTitle={"Mokhasan - Admin Gallery"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="events" element={
                                <AdminEvents pageTitle={"Mokhasan - Admin Events"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="family" element={
                                <AdminFamily pageTitle={"Mokhasan - Admin Family"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="sponsors" element={
                                <AdminSponsors pageTitle={"Mokhasan - Admin Sponsors"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="contact_us" element={
                                <AdminContactUs pageTitle={"Mokhasan - Admin Contact Us"} isAdmin={this.state.isAdmin}/>
                            }/>
                        </Route>
                        <Route path="*" element={
                            <NotFound
                                pageTitle={"Mokhasan - 404 Not Found"}
                                bannerTitle={"404"}
                                bannerContent={"The Page Not Found"}
                                isAdmin={this.state.isAdmin}
                            />
                        }/>
                    </Routes>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
