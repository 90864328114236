import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, ListGroup} from "react-bootstrap";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import EventSidebar from "../Common/EventSidebar";
import MokhasanMap from "../Common/MokhasanMap";

class SingleEvents extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    render() {
        return (<>
                <Header/>
                <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

                <div className="content">
                    <div className="container">
                        <section className="sec-100px event event-page">
                            <Row>
                                <Col sm={8}>
                                    <ListGroup as={"ul"}>
                                        <Col as={"li"} sm={5} className="no-padding eve-img">
                                            <img className="img-responsive" src="/assets/images/temple_images/DSC_4219.jpg" alt=""/>
                                            <div className="date">09 <p>JUL, 15</p></div>
                                        </Col>
                                        <Col as={"li"} sm={7} className="no-padding">
                                            <div className="event-detail">
                                                <h4> Let’s Celebrate 25th Mokhasan Day </h4>
                                                <span><em className="ion-ios-location-outline"></em> Melbourne, Australia </span>
                                                <span><em className="ion-ios-clock-outline"></em> Wed 10AM - 12PM</span>
                                                <p>
                                                    I have always wanted to have a neighbor just like you. I've always wanted to live in a neighborhood with
                                                    you.
                                                    <br/><br/>
                                                    The helpless. The powerless in a world of criminals who operate above the law. And if you threw a party -
                                                    invited everyone you knew.
                                                </p>
                                                <Link to="/" className="btn">join the event</Link>
                                                &nbsp;&nbsp;
                                                <Link to="/" className="btn btn-1">view the calender</Link>
                                            </div>
                                        </Col>
                                    </ListGroup>

                                    <div className="event-location">
                                        <h4>Event Location</h4>

                                        <div style={{height: "380px"}}>
                                            <MokhasanMap/>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <EventSidebar/>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>

                <Footer/>
            </>
        )
    }
}

export default SingleEvents;