import React from "react";
import {ListGroup, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

function HoursOfVisiting() {
    return (
        <div className="hrs">
            <h3><em className="ion-ios-clock-outline"></em> Upcoming Event</h3>
            <ListGroup as={"ul"} className={"mb-0"}>
                <ListGroup.Item as={"li"} className={"col-md-5 no-padding bg-transparent border-0"}>
                    <h5>Mon - Wed</h5>
                    <p>8:00 Am to 7:00 Pm</p>
                </ListGroup.Item>
                <ListGroup.Item as={"li"} className={"col-md-7 no-padding bg-transparent border-0"}>
                    <span className="appoiment px-1">Mokhasan Convention 2023</span>
                </ListGroup.Item>
                <ListGroup.Item as={"li"} className={"col-md-12 no-padding bg-transparent border-0 d-flex flex-wrap flex-md-nowrap"}>
                    <Link to={"/register_your_interest"} className={"btn btn-primary d-inline-block w-100 w-md-50 mr-2"}>Register Your Interest</Link>
                    <Button className={"d-inline-block w-100 w-md-50 mr-2"}>More info</Button>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default HoursOfVisiting;