import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import {Button, Modal, Form, Alert, Col, Row, Card} from "react-bootstrap";
import {PostData} from "../api_key/PostData";
import {GetData} from "../api_key/GetData";

class AdminEvents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUsername: "",
            loginUserUUID: "",
            loginUserToken: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            eventImage: "",
            addEditEventModal: false,
            eventTitle: "",
            eventAddress: "",
            eventDate: "",
            eventTime: "",
            eventDescription: "",
        }
        document.title = this.props.pageTitle
        this.insertNewEvent = this.insertNewEvent.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.changeFileValue = this.changeFileValue.bind(this)
        this.loadAllEvents = this.loadAllEvents.bind(this)
    }

    componentDidMount() {
        const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
        let sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
        this.setState({
            loginUsername: sessionData[0].full_name,
            loginUserUUID: sessionData[0].uuid,
            loginUserToken: sessionData[0].token
        });

        this.loadAllEvents(sessionData[0].token)
    }

    insertNewEvent() {
        const formData = new FormData();
        formData.append('File', this.state.eventImage);
        PostData("/events/add_event", {
            banner_image_file: formData,
            title: this.state.eventTitle,
            location: this.state.eventAddress,
            start_date_time: this.state.eventDate,
            end_date_time: this.state.eventTime,
            description: this.state.eventDescription
        }, this.state.loginUserToken)
            .then(result => {
                console.log(result)
                if (result.status === true) {

                } else if (result.status === 401) {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: result.messages.message
                        }
                    })
                }
            })
    }

    loadAllEvents(token) {
        GetData("/events/get_all", token)
            .then(result => {
                this.setState({eventsData: result.response});
                const listItems = result.response.map((data, index) =>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={index}>
                        <div className="shadow rounded p-4">
                            <h4 className={"text-dark"}>{data.title}</h4>
                            <div className="d-flex align-items-center justify-content-between flex-wrap border-white border-bottom border-top py-2 mb-2">
                                <h6 className="mx-2 my-1 text-dark fw-normal event-address"><i className="fa fa-map-marker"></i> {data.location}</h6>
                                <h6 className="mx-2 my-1 text-dark fw-normal event-time"><i className="fa fa-calendar"></i> {data.start_date_time}</h6>
                                <h6 className="mx-2 my-1 text-dark fw-normal event-time"><i className="fa fa-clock-o"></i> {data.end_date_time}</h6>
                            </div>
                            <p>{data.description}</p>

                            <img className="img-responsive w-100" src={data.banner_image_file} alt="" style={{height: 160, objectFit: "cover"}}/>
                        </div>
                    </Col>
                );
                this.setState({eventsDataItems: listItems, dataLoader: false});
            })
    }

    handleClose() {
        this.setState({
            addEditEventModal: false,
            eventImage: "",
            eventTitle: "",
            eventAddress: "",
            eventDate: "",
            eventTime: "",
            eventDescription: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    changeFileValue = (event) => {
        this.setState({
            eventImage: event.target.files[0]
        })
    }

    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="d-flex justify-content-end mb-3 ml-2">
                            <Button variant={"primary"} onClick={() => {
                                this.setState({
                                    addEditEventModal: true,
                                })
                            }}>Add New Event</Button>
                        </div>
                        <Modal show={this.state.addEditEventModal} onHide={this.handleClose} backdrop={"static"} centered keyboard={false}>
                            <Modal.Header closeButton className={"border-light"}>
                                <Modal.Title className={"text-dark"}>Add New Event</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                    </Alert>
                                    <Form.Group className="mb-3" controlId="eventImage">
                                        <Form.Label>Event Image</Form.Label>
                                        <Form.Control type="file"
                                                      placeholder="Event Image"
                                                      className={"bg-white border-light"}
                                                      name={"eventImage"}
                                                      onChange={this.changeFileValue}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventTitle">
                                        <Form.Label>Event Title</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder="Event Title"
                                                      className={"bg-white border-light"}
                                                      name={"eventTitle"}
                                                      value={this.state.eventTitle}
                                                      onChange={this.changeValue}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventDate">
                                        <Form.Label>Event Date</Form.Label>
                                        <Form.Control type="date"
                                                      placeholder="Event Date"
                                                      className={"bg-white border-light"}
                                                      name={"eventDate"}
                                                      value={this.state.eventDate}
                                                      onChange={this.changeValue}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventTime">
                                        <Form.Label>Event Time</Form.Label>
                                        <Form.Control type="time"
                                                      placeholder="Event Time"
                                                      className={"bg-white border-light"}
                                                      name={"eventTime"}
                                                      value={this.state.eventTime}
                                                      onChange={this.changeValue}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventAddress">
                                        <Form.Label>Event Address</Form.Label>
                                        <Form.Control as="textarea"
                                                      placeholder="Event Address"
                                                      rows={3}
                                                      className={"bg-white border-light"}
                                                      name={"eventAddress"}
                                                      value={this.state.eventAddress}
                                                      onChange={this.changeValue}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventDescription">
                                        <Form.Label>Event Description</Form.Label>
                                        <Form.Control as="textarea"
                                                      rows={3}
                                                      placeholder="Event Description"
                                                      className={"bg-white border-light"}
                                                      name={"eventDescription"}
                                                      value={this.state.eventDescription}
                                                      onChange={this.changeValue}/>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className={"border-light"}>
                                <Button variant={"primary"} onClick={this.handleClose} className={"ml-2"}>Close</Button>
                                <Button variant={"success"} onClick={this.insertNewEvent} className={"ml-2"}>Save Changes</Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="row g-4">
                            {this.state.eventsDataItems}
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminEvents;